<template>
   <div class="personnel">
      <!--    搜索    -->
      <div ref="search" class="search">
         <el-card class="box-card" shadow="never" :body-style="{padding:'10px'}">
            <el-form :inline="true" :model="searchForm" label-width="80px">
               <search-open-btn :searchShow="searchShow" @searchOpen="searchOpen"></search-open-btn>
               <el-form-item label="姓名">
                  <el-input clearable v-model="searchForm.sysUiName" :size="$store.state.size" placeholder="姓名" @keyup.enter.native="searchSubmit(true)"></el-input>
               </el-form-item>
               <el-form-item label="联系电话">
                  <el-input clearable v-model="searchForm.sysUiTel" :size="$store.state.size" placeholder="电话" @keyup.enter.native="searchSubmit(true)"></el-input>
               </el-form-item>
               <el-form-item label="客户类型">
                  <el-select clearable v-model="searchForm.sysCiType" :size="$store.state.size" placeholder="客户类型" @change="remarksChange">
                     <el-option v-for="e of remarkslist" :key="e.code" :label="e.remarks" :value="e.code"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="所属客户">
                  <el-select clearable filterable v-model="searchForm.sysCiId" :size="$store.state.size" placeholder="所属客户">
                     <el-option v-for="e in customerlistBySearch" :key="e.sysCiId" :label="e.sysCiShortName" :value="e.sysCiId"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="所属角色" v-show="searchShow">
                  <el-select clearable v-model="searchForm.sysRiId" :size="$store.state.size" placeholder="所属角色" @change="roleChange">
                     <el-option v-for="e in roleList" :key='e.sysRiId' :label="e.sysRiName" :value="e.sysRiId"></el-option>
                  </el-select>
               </el-form-item>
               <!-- <el-form-item label="接收消息提醒" label-width="100px" v-show="searchShow">
            <el-select v-model="searchForm.sysUiAcceptMessage" :size="$store.state.size" placeholder="接收消息提醒" clearable>
              <el-option label="是" value="0"></el-option>
              <el-option label="否" value="1"></el-option>
            </el-select>
          </el-form-item> -->
            </el-form>
            <el-divider></el-divider>
            <el-button v-if="hasPermission('2-5-a')" type="primary" :size="$store.state.size" @click="searchSubmit(true)" icon="el-icon-search">查询
            </el-button>
            <el-button v-if="hasPermission('2-5-b')" type="default" plain :size="$store.state.size" @click="resetSubmit" icon="el-icon-delete">清空
            </el-button>
            <el-button v-if="hasPermission('2-5-c')" type="primary" :size="$store.state.size" @click="saveOpen" icon="el-icon-circle-plus-outline">新增
            </el-button>
         </el-card>
      </div>
      <!--    人员列表    -->
      <el-table :data="tableData" :height="tableHeight" v-loading="tableLoading" border @sort-change="sortChange" :size="this.$common.tableLineHeight()" :header-cell-style="{background:'#f5f7fa',color:'#515a6e', fontWeight:'900'}" style="width: 100%; margin-top: 10px">
         <el-table-column prop="sysUiName" align="center" label="姓名">
         </el-table-column>
         <el-table-column prop="sysUiTel" align="center" label="联系电话">
         </el-table-column>
         <el-table-column prop="customerTypeStr" align="center" label="客户类型">
         </el-table-column>
         <el-table-column prop="customer" align="center" label="所属客户">
         </el-table-column>
         <el-table-column prop="role" align="center" label="所属角色">
         </el-table-column>
         <!-- <el-table-column
          prop="sysUiAcceptMessage"
          align="center"
          label="接收消息提醒"
          >
        <template slot-scope="scope">
          <el-switch
              v-model="scope.row.sysUiAcceptMessage"
              active-value="0"
              inactive-value="1"
              active-text="是"
              inactive-text="否"
              @change="xiaoxiSubmit(scope.row)"
              v-if="scope.row.sysUiId!='1'">
          </el-switch>
          <div v-if="scope.row.sysUiId=='1'"></div>
        </template>
      </el-table-column> -->
         <el-table-column align="center" label="操作" width="260">
            <template slot-scope="scope">
               <el-button v-if="scope.row.sysUiId!='1'&&hasPermission('2-5-a')" type="text" size="small" @click="changePassword(scope.row)" icon="el-icon-lock">修改密码
               </el-button>
               <el-button v-if="scope.row.sysUiId!='1'&&hasPermission('2-5-d')" type="text" size="small" @click="seeOpen(scope.row)" icon="el-icon-document">查看
               </el-button>
               <el-button v-if="scope.row.sysUiId!='1'&&hasPermission('2-5-e')" type="text" size="small" @click="editOpen(scope.row)" icon="el-icon-edit-outline">编辑
               </el-button>
               <el-button v-if="scope.row.sysUiId!='1'&&hasPermission('2-5-f')" type="text" size="small" @click="delOpen(scope.row)" icon="el-icon-delete">删除
               </el-button>
               <div v-if="scope.row.sysUiId=='1'"></div>
            </template>
         </el-table-column>
      </el-table>
      <pagination :page-size='currentPageSize' :current-page="currentPage" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </pagination>

      <!--    保存模态框-->
      <assembly-dialog :title="dialogTitle" :visible.sync="dialogVisibleSave" width="30%" @before-close="handleClose">
         <el-form :model="saveForm" :rules="saveRules" ref="saveForm" label-width="80px">
            <el-form-item label="姓名" prop="sysUiName">
               <el-input v-model="saveForm.sysUiName" :size="$store.state.size" placeholder="姓名"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="sysUiPwd" v-if="showPosswor">
               <el-input v-model="saveForm.sysUiPwd" :size="$store.state.size" placeholder="密码"></el-input>
            </el-form-item>
            <el-form-item label="联系电话" prop="sysUiTel">
               <el-input v-model="saveForm.sysUiTel" :size="$store.state.size" placeholder="联系电话"></el-input>
            </el-form-item>
            <el-form-item label="客户类型" prop="customerTypeStr">
               <el-select v-model="saveForm.customerTypeStr" :size="$store.state.size" placeholder="客户类型" @change="saveRemarksChange">
                  <el-option v-for="e of remarkslist" :key="e.code" :label="e.remarks" :value="e.code"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="所属客户" prop="customer">
               <el-select filterable v-model="saveForm.customer" :size="$store.state.size" placeholder="所属客户" @change="customerChange">
                  <el-option v-for="e in customerlist" :key="e.sysCiId" :label="e.sysCiShortName" :value="e.sysCiId"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="所属角色" prop="role">
               <el-select v-model="saveForm.role" :size="$store.state.size" placeholder="所属角色" @change="roleChange">
                  <el-option v-for="e in roleList" :key='e.sysRiId' :label="e.sysRiName" :value="e.sysRiId"></el-option>
               </el-select>
            </el-form-item>
            <!-- <el-form-item label="接收消息" prop="sysUiAcceptMessage">
          <el-select v-model="saveForm.sysUiAcceptMessage" :size="$store.state.size" placeholder="接收消息提醒" clearable>
            <el-option label="是" value="0"></el-option>
            <el-option label="否" value="1"></el-option>
          </el-select>
        </el-form-item> -->
         </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="clearForm('saveForm')">清 空</el-button>
            <el-button :size="$store.state.size" type="primary" @click="saveSubmit('saveForm')">确 定</el-button>
         </span>
      </assembly-dialog>
      <!--    查看模态框-->
      <assembly-dialog title="查看" :visible.sync="dialogVisibleSee" width="30%" @before-close="seeClose">
         <el-descriptions class="margin-top" :column="1" :size="$store.state.size" border>
            <el-descriptions-item>
               <template slot="label">
                  姓名
               </template>
               {{ seeForm.sysUiName }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  联系电话
               </template>
               {{ seeForm.sysUiTel }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  客户类型
               </template>
               {{ seeForm.customerTypeStr }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  所属客户
               </template>
               {{ seeForm.customer }}
            </el-descriptions-item>
            <el-descriptions-item>
               <template slot="label">
                  所属角色
               </template>
               {{ seeForm.role }}
            </el-descriptions-item>
            <!-- <el-descriptions-item>
          <template slot="label">
            接收消息
          </template>
          {{seeForm.sysUiAcceptMessage === '0'?'是':'否'}}
        </el-descriptions-item> -->
         </el-descriptions>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="seeClose">关 闭</el-button>
         </span>
      </assembly-dialog>
      <!--    修改密码模态框   -->
      <assembly-dialog title="修改密码" :visible.sync="dialogPassword" width="30%" @before-close="passwordClose;dialogPassword=false">
         <el-form :model="passwordForm" :rules="passwordRules" ref="passwordForm" label-width="80px">
            <el-form-item label="密码" prop="sysUiPwd">
               <el-input v-model="passwordForm.sysUiPwd" :size="$store.state.size" placeholder="密码" clearable></el-input>
            </el-form-item>
         </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="passwordClose('passwordForm')">清 空</el-button>
            <el-button :size="$store.state.size" type="primary" @click="savePasswordSubmit('passwordForm')">确 定</el-button>
         </span>
      </assembly-dialog>
   </div>
</template>

<script>
import searchOpenBtn from "@/components/assembly-search/search-open-btn";
import pagination from "@/components/assembly-pagination/index";
import AssemblyDialog from "components/assembly-dialog";

export default {
   name: "personnel",
   components: {
      AssemblyDialog,
      searchOpenBtn,
      pagination
   },
   watch: {
      //监听头部展开收起变化
      '$store.state.isHeader'() {
         this.setTableHeight()
      },
      //监听快速导航展开收起变化
      '$store.state.fastNav'() {
         this.setTableHeight()
      },
      //监听搜索区域变化改变列表
      searchShow() {
         this.setTableHeight()
      },
   },

   data() {
      return {
         currentPageSize: 10,
         dialogTitle: '新增',
         //搜索区域展开收起开关
         searchShow: false,
         //搜索区域展开收起图标
         searchOpenIcon: 'el-icon-arrow-down',
         //加载
         tableLoading: false,
         //表格高度
         tableHeight: 0,
         //表格数据
         tableData: [],
         //当前页面
         currentPage: 1,
         //最大页数
         total: 0,
         //搜索表单
         searchForm: {
            page: 1,
            pageSize: this.$store.state.initPageSize,
            sysUiName: '',
            sysUiTel: '',
            sysCiType: '',
            customerName: '',
            sysRiId: '',
            roleName: '',
            sysUiAcceptMessage: ''
         },
         //保存模态框开关
         dialogVisibleSave: false,
         //显示密码输入框
         showPosswor: false,
         //保存表单
         saveForm: {
            sysUiName: '',
            sysUiTel: '',
            sysUiPwd: '',
            customerTypeStr: '',
            sysCiTypeID: '',
            customer: '',
            customerName: '',
            roleId: '',
            role: '',
            sysUiAcceptMessage: '0',
         },
         //保存表单验证
         saveRules: {
            sysUiName: [
               { required: true, validator: this.$verification.personnelName, trigger: 'blur' }
            ],
            sysUiPwd: [
               { required: true, validator: this.$verification.validatePassWord, trigger: 'blur' }
            ],
            sysUiTel: [
               { required: true, validator: this.$verification.personnelTel, trigger: 'blur' }
            ],
            customerTypeStr: [
               { required: true, message: '请选择客户类型', trigger: 'change' }
            ],
            customer: [
               { required: true, message: '请选择所属客户', trigger: 'change' }
            ],
            role: [
               { required: true, message: '请选择所属角色', trigger: 'change' }
            ],
            sysUiAcceptMessage: [
               { required: true, message: '请选择接收消息提醒', trigger: 'change' }
            ]
         },
         passwordRules: {
            sysUiPwd: [
               { required: true, validator: this.$verification.validatePassWord, trigger: 'blur' }
            ],
         },
         //查看模态框开关
         dialogVisibleSee: false,
         seeForm: {
            sysUiName: '',
            sysUiTel: '',
            type: '',
            customer: '',
            customerTypeStr: '',
            roleId: '',
            role: '',
            sysUiAcceptMessage: ''
         },
         dialogPassword: false,
         passwordForm: {
            sysUiPwd: '',
            sysUiId: '',
         },
         //  客户类型列表
         remarkslist: [],
         //  客户列表(筛选区)
         customerlistBySearch: [],
         //  客户列表
         customerlist: [],
         //  角色列表
         roleList: [],
         isSave: 1, //编辑新增传1，查询传0
         isAdd: true

      }
   },
   mounted() {
      //初始化计算表格区域高度
      this.setTableHeight();
      //初始化人员表单
      this.searchSubmit();
      //初始化客户类型列表
      this.findCustomerType()
      //  初始化角色列表
      this.roleGetList()
   },
   methods: {
      //判断权限是否存在
      hasPermission(item) {
         return this.$common.hasPermission(item);
      },

      /****表格高度计算 start********************************************************************************/
      //计算表格区域高度
      setTableHeight() {
         this.$nextTick(() => {
            this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
            window.onresize = () => {
               return (() => {
                  this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
               })();
            };
         })
      },
      /****表格高度计算 end********************************************************************************/
      /****客户类型 start********************************************************************************/
      //客户类型获取
      findCustomerType() {
         this.$server.findCustomerType().then((res) => {
            if (res.ok) {
               // this.$alert(this.searchForm, '请求数据', {
               //   confirmButtonText: res.data
               // });
               this.remarkslist = res.data
            }
         }).catch(e => {
            console.log(e)
         })
      },
      //客户类型更改
      remarksChange(e) {
         if (!e) {
            this.customerlistBySearch = []
            this.saveForm.sysCiTypeID = undefined
            return
         }
         this.saveForm.sysCiTypeID = e
         // this.searchForm.sysCiType = e
         this.getList()
      },

      /****客户类型end********************************************************************************/
      /****所属客户 start********************************************************************************/
      // 根据客户类型查询客户列表getList
      getList() {
         let data = {
            sysCiType: this.saveForm.sysCiTypeID
         }
         this.$server.getList(data).then((res) => {
            if (res.ok) {
               // console.log('获取客户列表', JSON.stringify(res.data))

               // this.$alert(data, '请求数据', {
               //   confirmButtonText: res.data
               // })
               if (this.dialogVisibleSave) {
                  this.customerlist = res.data
               } else {
                  this.customerlistBySearch = res.data
               }
            }
         }).catch(e => {
            console.log(e)
         })
      },
      //选择所属客户
      customerChange(e) {
         // console.log('选择所属客户', e)
         this.saveForm.customerId = e
         // this.searchForm.sysCiType = e

      },
      /****所属客户 end********************************************************************************/
      /****角色查询区域 start********************************************************************************/
      //查询角色列表
      roleGetList() {
         this.isSave = this.isAdd == true ? 0 : 1
         this.$server.roleGetList({ isSave: this.isSave }).then((res) => {
            if (res.ok) {
               this.roleList = res.data
            }
         }).catch(e => {
            console.log(e)
         })
      },
      //角色选择
      roleChange(e) {
         // console.log('角色选择', e)
         this.saveForm.roleId = e
         // this.searchForm.sysRiId = e
      },
      /****角色查询区域 end********************************************************************************/

      /****搜素区域 start********************************************************************************/
      //搜索展开收起事件
      searchOpen(searchShow) {
         this.searchShow = searchShow
      },
      _resetPage() {
         this.currentPage = 1,
            this.searchForm.page = 1
      },
      //搜索表单提交
      searchSubmit(flag) {
         if (flag) {
            this.currentPage = 1
            this.searchForm.page = 1
            this.searchForm.pageSize = this.currentPageSize
         }
         // console.log('角色选择', JSON.stringify(this.searchForm))
         this.tableLoading = true
         this.$server.getByPage(this.searchForm).then((res) => {
            this.tableLoading = false
            if (res.ok) {
               // console.log('搜索表单提交', JSON.stringify(this.searchForm))

               this.tableData = res.data.records
               this.total = res.data.total
            }
         }).catch(e => {
            this.tableLoading = false
            console.log(e)
         })
      },
      //搜索表单重置
      resetSubmit() {
         this.$common.clearFormValue(this.searchForm)
         this.searchForm.page = 1
         this.searchForm.pageSize = this.currentPageSize
         this.searchSubmit()
         this.currentPage = 1
         this.isAdd = false;
         this.roleGetList();
         // this.$alert(this.searchForm, '请求数据', {
         //   confirmButtonText: '确定'
         // });
      },
      /****搜素区域 end********************************************************************************/
      /****保存区域 start********************************************************************************/
      //保存表单提交
      saveOpen() {
         this.dialogTitle = '新增'
         //是否显示密码
         this.showPosswor = true
         this.dialogVisibleSave = true
         this.isAdd = false;
         this.roleGetList();

      },
      //模态框关闭前回调
      handleClose() {
         this.dialogVisibleSave = false
         this.showPosswor = false
         this.isAdd = true;
         this.roleGetList();
         this.clearForm('saveForm')
      },
      //保存提交
      saveSubmit(formName) {
         this.$refs[formName].validate((valid) => {
            if (valid) {
               this.$server.saveOrUpdate(this.saveForm).then(res => {
                  if (res.ok) {
                     this._resetPage()
                     this.$message.success(res.msg)
                     this.searchSubmit()
                  } else {
                     this.$message.error(res.msg)
                  }
                  this.dialogVisibleSave = false
                  this.showPosswor = false;
                  this.isAdd = true;
                  this.roleGetList();
                  this.clearForm('saveForm')
               }).catch(e => {
                  console.log(e)
               })
            } else {
               console.log('error submit!!');
               return false;
            }
         });
      },
      //接受消息提醒修改
      xiaoxiSubmit(formName) {
         this.$server.saveOrUpdate(formName).then(res => {
            if (res.ok) {
               this.$message.success(res.msg)
               this.searchSubmit()
            } else {
               this.$message.error(res.msg)
            }
            this.dialogVisibleSave = false
            this.$common.clearFormValue(this.saveForm)
         }).catch(e => {
            console.log(e)
         })
      },
      //清空表单
      clearForm(formName) {
         this.$refs.saveForm.resetFields();
         this.saveForm.sysCiTypeID = "";
         this.saveForm.customerId = "";
         this.saveForm.roleId = "";
         this.customerlist = []
         // if(this[formName].sysUiId){
         //   this[formName].sysUiName = "";
         //   this[formName].sysUiPwd = "";
         //   this[formName].sysUiTel = "";
         //   this[formName].customerTypeStr = "";
         //   this[formName].customer = "";
         //   this[formName].role = "";
         // }else{
         //   this.$common.clearFormValue(this.saveForm)
         // }
      },
      //编辑模态框开启
      editOpen(row) {
         this.dialogTitle = '编辑'
         this.dialogVisibleSave = true
         this.isAdd = false;
         this.roleGetList();
         this.$nextTick(() => {
            this.saveForm = JSON.parse(JSON.stringify(row))
         })

      },
      //新增选择客户类型
      saveRemarksChange(e) {
         this.customerlist = [];
         if (this.saveForm.sysCiTypeID != e) {
            this.saveForm.sysCiTypeID = e
            this.saveForm.customer = ''
         }
         this.getList()

      },
      /****保存区域 end********************************************************************************/
      /****查看区域 start********************************************************************************/
      seeOpen(row) {
         this.seeForm = row
         this.dialogVisibleSee = true
      },
      seeClose() {
         this.dialogVisibleSee = false
      },
      /****查看区域 end********************************************************************************/
      /****修改密码 start********************************************************************************/
      //修改密码
      changePassword(row) {
         this.dialogPassword = true
         this.passwordForm.sysUiId = row.sysUiId
      },
      passwordClose() {
         this.dialogPassword = false
      },
      //修改密码保存提交
      savePasswordSubmit(formName) {
         // console.log('修改密码保存提交', JSON.stringify(this.passwordForm))
         this.$refs[formName].validate((valid) => {
            if (valid) {
               this.$server.updatePasswordForce(this.passwordForm).then(res => {
                  if (res.ok) {
                     this.$message.success(res.msg)
                     this.passwordClose()
                     this.searchSubmit()
                  } else {
                     this.$message.error(res.msg)
                  }
                  this.dialogVisibleSave = false
                  this.$common.clearFormValue(this.passwordForm)
               }).catch(e => {
                  console.log(e)
               })
            } else {
               console.log('error submit!!');
               return false;
            }
         });
      },
      /****修改密码 end********************************************************************************/

      /****删除区域 start********************************************************************************/
      delOpen(row) {
         // console.log('删除人员', JSON.stringify(row))
         this.$confirm('确认删除该条数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            this.$server.userDelete({ id: row.sysUiId }).then(res => {
               if (res.ok) {
                  this.$message.success(res.msg)
                  this.searchSubmit()
               } else {
                  this.$message.error(res.msg)
               }
            }).catch(e => {
               console.log(e)
            })
         }).catch(() => {
            this.$message({
               type: 'info',
               message: '已取消删除'
            });
         });
      },
      /****删除区域 end********************************************************************************/
      handleSizeChange(val) {
         this.currentPage = 1
         this.currentPageSize = val
         this.searchForm.pageSize = val
         this.searchForm.page = 1
         this.searchSubmit()
      },
      handleCurrentChange(val) {
         this.searchForm.page = val
         this.searchSubmit()
         this.currentPage = val
      },
      //排序
      sortChange(row) {
         // console.log('排序排序排序', JSON.stringify(row))
         if (row.prop == 'customerTypeStr') {
            this.searchForm.orderByColumn = 'sysCiType'
         } else if (row.prop == 'customer') {
            this.searchForm.orderByColumn = 'sysCiShortName'
         } else if (row.prop == 'role') {
            this.searchForm.orderByColumn = 'sysRiName'
         } else {
            this.searchForm.orderByColumn = row.prop
         }
         if (row.order == 'ascending') {
            this.searchForm.order = 'asc'
         } else if (row.order == 'descending') {
            this.searchForm.order = 'desc'

         } else {
            this.searchForm.order = ''
         }
         this.searchForm.page = 1;
         this.searchSubmit()
      },
      onSubmit() {
         console.log('submit!');
      }
   },
}
</script>

<style lang="scss">
.personnel {
   .search {
      //搜索展开收起按钮位置设置
      .searchOpenBtn {
         float: right;
      }

      //表单每行底部外边距设置
      .el-form-item {
         margin-bottom: 10px;
      }

      .el-select {
         width: 200px;
      }

      .el-input {
         width: 200px;
      }

      //分割线外边距设置
      .el-divider--horizontal {
         margin: 0 0 10px 0;
      }
   }

   .el-dialog {
      .el-select {
         width: 100%;
      }
   }
}
</style>

<style scoped>
::v-deep .el-dialog {
   display: flex;
   flex-direction: column;
   margin: 0 !important;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   max-height: calc(100% - 30px);
   max-width: calc(100% - 30px);
}
</style>
